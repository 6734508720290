import React, { useState, useEffect } from 'react'
import { Select } from 'baseui/select'
import { Block } from 'baseui/block'
import { Calendar16 } from '@carbon/icons-react'
import moment from 'moment'

const YearSelect = ({ year, setYear }) => {
  const [ yearsOption, setYearOption ] = useState([])
  useEffect(() => {
    const years = []
    const startYear = 2562
    const presentYear =
      parseInt(moment().format('YYYY')) +
      (moment().isAfter(moment().month('7')) ? 543 : 542)
    for (let i = startYear; i <= presentYear; i++) {
      years.push({ id: i.toString(), label: `ปีการศึกษา ${i}` })
    }
    setYearOption(years)
  }, [])
  return (
    <Block display="flex" alignItems="center">
      <Calendar16/>
      <Select
        options={yearsOption}
        value={yearsOption.find(item => item.id === year)}
        onChange={({ value }) => setYear(value[0].id)}
        overrides={{
          Root: {
            style: () => {
              return {
                outline: 'white',
                backgroundColor: 'white',
                width: '140px'
              }
            },
          },
          ControlContainer: {
            style: () => {
              return {
                alignItems: 'center',
                border: 'none',
                outline: 'white',
                backgroundColor: 'white',
              }
            },
          },
          ValueContainer: {
            style: () => ({
              fontSize: '12px'
            })
          }
        }}
      />
    </Block>
  )
}

export default YearSelect
