import React from "react"
import { get } from "lodash"
import { navigate } from "gatsby"
import withUser from "hoc/withUser"
import { Block } from "baseui/block"
import { StatefulPopover } from "baseui/popover"
import { StatefulMenu } from "baseui/menu"
import { GoogleLogout } from "react-google-login"
import ProfileAvatar from "components/atoms/ProfileAvatar"

const ProfileAvatarWithMenu = ({ userState, userDispatch }) => {
  const name = `${get(userState, "user.name")} ${get(
    userState,
    "user.family_name"
  )}`
  const img = get(userState, "user.img")
  return (
    <StatefulPopover
      showArrow
      content={
        <GoogleLogout
          clientId={process.env.GOOGLE_AUTH_CLIENT_ID}
          hostedDomain={"kmitl.ac.th"}
          render={renderProps => (
            <StatefulMenu
              onItemSelect={({ item }) => {
                if (item.label === "Dashboard") return navigate("/post-job/")
                else {
                  userDispatch({ type: "CLEAR_USER_DATA" })
                  localStorage.removeItem("tokenId")
                  localStorage.removeItem("refreshToken")
                  renderProps.onClick()
                  return navigate("/")
                }
              }}
              overrides={{
                List: {
                  style: {
                    boxShadow: "none",
                    outline: "none",
                  },
                },
              }}
              items={[
                {
                  label: "Dashboard",
                },
                { label: "Log Out" },
              ]}
            />
          )}
        />
      }
      accessibilityType={"tooltip"}
    >
      <Block display={"flex"} style={{ cursor: "pointer" }}>
        <ProfileAvatar invert name={name} size={"48px"} src={img} />
      </Block>
    </StatefulPopover>
  )
}

export default withUser(ProfileAvatarWithMenu)
