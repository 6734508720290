import React from "react"
import { Label3 } from "baseui/typography"
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table"

const TopFiveTable = ({ data, headers, widths, keys }) => {
  return (
    <StyledTable>
      <StyledHead>
        {headers.map((header, index) => (
          <StyledHeadCell
            style={{ maxWidth: widths[index], textAlign: "center" }}
          >
            <Label3 margin="auto" style={{ fontWeight: "bold" }}>
              {header}
            </Label3>
          </StyledHeadCell>
        ))}
      </StyledHead>
      <StyledBody>
        {data.map((item, index) => (
          <StyledRow
            style={{
              paddingTop: 10,
              PaddingBottom: 10,
              backgroundColor:
                (index + 1) % 2 === 0
                  ? "rgba(247, 247, 247, 0.5)"
                  : "transparent",
            }}
          >
            <StyledCell
              style={{ maxWidth: widths[0], wordBreak: "break-word" }}
            >
              {item[keys[0]]}
            </StyledCell>
            <StyledCell
              style={{ maxWidth: widths[1], wordBreak: "break-word" }}
            >
              {item[keys[1]]}
            </StyledCell>
            <StyledCell
              style={{
                maxWidth: widths[2],
                justifyContent: keys[2] === "count" ? "center" : "flex-start",
              }}
            >
              {item[keys[2]]}
            </StyledCell>
          </StyledRow>
        ))}
      </StyledBody>
    </StyledTable>
  )
}

export default TopFiveTable
