import React  from 'react'
import { Block } from 'baseui/block'
import { navigate } from 'gatsby'
import { Navigation } from 'baseui/side-navigation'
import checkPermission from 'hoc/checkPermission'
import { H4 } from 'baseui/typography'
import NavigateMenu from 'components/NavigateMenu'
import logo from 'assets/images/kmitl-logo.png'
import ProfileAvatarWithMenu from 'components/ProfileAvatarWithMenu'
import withUser from './withUser'

const routes = [
  {
    title: 'Posted Job Report',
    itemId: 'post-job',
  },
  {
    title: 'Applied Job Report',
    itemId: 'applied-job',
  },
  {
    title: 'User',
    itemId: 'user',
  },
  {
    title: 'Work Place',
    itemId: 'work-place',
  },
]

const WithAdminNavigate = WrappedComponent => {
  const WrappedComponentWithAdminNavigate = props => {
    const { location } = props
    return (
      <Block display={'flex'} minHeight="100vh" height="100%">
        <Block flex={0.25} display="flex">
          <Block
            width="64px"
            backgroundColor="#f5792e"
            display="flex"
            flexDirection="column"
            height="auto"
            // position="fixed"
          >
            <Block
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              margin='8px'
              height='100%'
            >
              <img
                src={logo}
                style={{
                  width: 55,
                  height: 55,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                alt={'logo'}
              />
              <Block marginLeft="auto" marginRight="auto">
                <ProfileAvatarWithMenu/>
              </Block>
            </Block>
          </Block>
          <Block
            // position={'fixed'}
            width={'223px'}
            height="100%"
            padding={'0px 5px'}
            // marginLeft={'72px'}
          >
            <H4 margin={'16px 0px'}>Dashboard</H4>
            <Navigation
              overrides={{
                NavItem: {
                  style: ({ $active, $theme }) =>
                    $active
                      ? {
                        fill: $theme.colors.primary,
                        ':hover': {
                          fill: $theme.colors.primary,
                          color: $theme.colors.primary,
                          backgroundColor: $theme.colors.primary,
                        },
                      }
                      : {
                        ':hover': {
                          fill: $theme.colors.primary,
                          color: $theme.colors.primary,
                        },
                      },
                },
              }}
              items={routes.map(route => ({
                title: (
                  <NavigateMenu
                    menu={route}
                  />
                ),
                itemId: route.itemId,
              }))}
              activeItemId={location.pathname.split('/')[1]}
              onChange={({ event, item }) => {
                event.preventDefault()
                navigate(item.itemId)
              }}
            />
          </Block>
        </Block>
        <Block flex={0.75} padding="30px" backgroundColor="#f3f3f3">
          <WrappedComponent {...props} />
        </Block>
      </Block>
    )
  }
  return withUser(checkPermission([ 'admin' ], WrappedComponentWithAdminNavigate))
}

export default WithAdminNavigate
