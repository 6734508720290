import React from "react"
import { Store } from "../store"

const WithDashboard = WrappedComponent => {
  const WrappedComponentWithRedux = props => {
    const data = React.useContext(Store)
    const dashboardState = data.dashboard[0]
    const dashboardDispatch = data.dashboard[1]
    return (
      <WrappedComponent
        {...props}
        dashboardState={dashboardState}
        dashboardDispatch={dashboardDispatch}
      />
    )
  }
  return WrappedComponentWithRedux
}

export default WithDashboard
