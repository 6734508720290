import React from "react"
import { Block } from "baseui/block"
import { H6, H5 } from "baseui/typography"
import {
  Task32,
  ToolBox32,
  CheckmarkFilled32,
  CloseFilled32,
  Location32,
  Building32,
  Bus32,
  Group32,
  UserIdentification32,
  Education32,
} from "@carbon/icons-react"

const ReportBox = ({ width, title, value, type }) => {
  const renderIcon = () => {
    if (type === "success")
      return <Task32 style={{ width: 50, height: 50, fill: "#42be65" }} />
    if (type === "summary")
      return <ToolBox32 style={{ width: 50, height: 50, fill: "#f1c21b" }} />
    if (type === "approve")
      return (
        <CheckmarkFilled32 style={{ width: 50, height: 50, fill: "#ff832b" }} />
      )
    if (type === "fail")
      return (
        <CloseFilled32 style={{ width: 50, height: 50, fill: "#da1e28" }} />
      )
    if (type === "building")
      return <Building32 style={{ width: 50, height: 50, fill: "#ff832b" }} />

    if (type === "bus")
      return <Bus32 style={{ width: 50, height: 50, fill: "#f1c21b" }} />

    if (type === "userGroup")
      return <Group32 style={{ width: 50, height: 50, fill: "black" }} />

    if (type === "professor")
      return (
        <UserIdentification32
          style={{ width: 50, height: 50, fill: "#00539a" }}
        />
      )

    if (type === "student")
      return <Education32 style={{ width: 50, height: 50, fill: "#ff832b" }} />

    return <Location32 style={{ width: 50, height: 50, fill: "black" }} />
  }

  const renderValueColor = () => {
    if (type === "success") return "#42be65"
    if (type === "summary" || type === "bus") return "#f1c21b"
    if (type === "approve" || type === "building" || type === "student")
      return "#ff832b"
    if (type === "fail") return "#da1e28"
    if (type === "professor") return "#00539a"
    return "black"
  }

  return (
    <Block
      width={width}
      display="flex"
      padding="20px"
      justifyContent="space-between"
      alignItems={"center"}
      style={{
        boxShadow: "2px 2px 8px 2px rgba(0, 0, 0, 0.1)",
        borderTop: "5px solid #ff7c1b",
      }}
      backgroundColor="white"
    >
      {renderIcon()}
      <Block style={{ textAlign: "end" }}>
        <H6
          marginTop={"0px"}
          marginBottom={"10px"}
          style={{ fontWeight: "normal" }}
        >
          {title}
        </H6>
        <H5
          marginTop={"0px"}
          marginBottom={"10px"}
          color={renderValueColor()}
          style={{ fontWeight: "bold" }}
        >
          {value}
        </H5>
      </Block>
    </Block>
  )
}

export default ReportBox
