import React, { useEffect } from 'react'
import withAdminNavigate from 'hoc/withAdminNavigate'
import { Block } from 'baseui/block'
import { H5, Label2 } from 'baseui/typography'
import ReportBox from 'components/ReportBox'
import withDashboard from 'hoc/withDashboard'
import { get } from 'lodash'
import moment from 'moment'

import PieGraphSection from 'components/PieGraphSection'
import BarGraphSection from 'components/BarGraphSection'
import ScrollTable from 'components/ScrollTable'
import TopFiveTable from 'components/TopFiveTable'
import Loader from 'components/Loader'

import {
  getAppliedJobSummary,
  getMostAppliedJob,
  getAppliedJobStaticYear,
  getAppliedJobStudyYear,
  getAppliedJobStaticFaculty,
  setActiveStudyYear,
  setAppliedJobYear,
} from '../actions/dashboard'

const AppliedJob = ({ dashboardState, dashboardDispatch }) => {
  const {
    appliedJobSummary,
    mostAppliedJob,
    appliedJobStaticYear,
    appliedJobStudyYear,
    appliedJobStaticFaculty,
    activeStudyYear,
    appliedJobYear,
  } = dashboardState
  useEffect(() => {
    const presentYear =
      parseInt(moment().format('YYYYY')) +
      (moment().isAfter(moment().month('7')) ? 543 : 542)
    if (!appliedJobSummary) {
      getAppliedJobSummary(dashboardDispatch)
    }
    if (!mostAppliedJob) {
      getMostAppliedJob(dashboardDispatch)
    }
    if (!appliedJobStaticYear) {
      getAppliedJobStaticYear(dashboardDispatch, presentYear)
    }
    if (!appliedJobStudyYear) {
      getAppliedJobStudyYear(dashboardDispatch, presentYear)
    }
    if (!appliedJobStaticFaculty) {
      getAppliedJobStaticFaculty(dashboardDispatch)
    }
    if (!activeStudyYear) {
      setActiveStudyYear(dashboardDispatch, presentYear.toString())
    }
    if (!appliedJobYear) {
      setAppliedJobYear(dashboardDispatch, presentYear.toString())
    }
  }, [])
  return (
    <Block width="100%">
      <H5 marginTop="0px" marginBottom="10px">
        Applied Job Report
      </H5>
      <Block display="flex" justifyContent="space-between">
        <ReportBox
          title={'สมัคร'}
          value={appliedJobSummary ? appliedJobSummary.pending : ''}
          width={'28%'}
          type={'summary'}
        />
        <ReportBox
          title={'ผ่านเกณฑ์'}
          value={appliedJobSummary ? appliedJobSummary.confirm : ''}
          width={'28%'}
          type={'success'}
        />
        <ReportBox
          title={'ไม่ผ่านเกณฑ์'}
          value={appliedJobSummary ? appliedJobSummary.reject : ''}
          width={'28%'}
          type={'fail'}
        />
      </Block>
      <Block marginTop="30px" display="flex" justifyContent="space-between">
        <Block width="60%">
          <Block>
            {get(appliedJobStaticYear, appliedJobYear) && appliedJobYear ? (
              <BarGraphSection
                title={'นักศึกษาที่สมัครในแต่ละเดือน'}
                data={get(appliedJobStaticYear, appliedJobYear)}
                keys={[ 'pending_job', 'confirm_job', 'reject_job' ]}
                year={appliedJobYear}
                setYear={year => {
                  !get(appliedJobStaticYear, year) &&
                  getAppliedJobStaticYear(dashboardDispatch, year)
                  setAppliedJobYear(dashboardDispatch, year)
                }}
              />
            ) : (
              <Loader/>
            )}
          </Block>
          <Block
            padding="16px"
            backgroundColor="white"
            style={{ borderTop: '4px solid #fd7c2e' }}
            marginTop="30px"
          >
            <Label2 style={{ fontWeight: 'bold', marginBottom: 16 }}>
              งาน 5 อันดับที่นักศึกษาสมัครมากที่สุด
            </Label2>
            {mostAppliedJob && mostAppliedJob.length > 0 ? (
              <TopFiveTable
                widths={[ '60%', '20%', '20%' ]}
                data={mostAppliedJob}
                keys={[ 'title', 'faculty_thai_name', 'count' ]}
                headers={[ 'ชื่องาน', 'คณะ/หน่วยงาน', 'จำนวนที่สมัคร' ]}
              />
            ) : (
              <Loader size="50px" height={50}/>
            )}
          </Block>
        </Block>
        <Block width="38%">
          {appliedJobStudyYear && get(appliedJobStudyYear, activeStudyYear) ? (
            <PieGraphSection
              data={get(appliedJobStudyYear, activeStudyYear).map(data => {
                if (data.status === '0')
                  return {
                    ...data,
                    status: 'อื่นๆ',
                  }
                return data
              })}
              dataKey={'count'}
              nameKey={'status'}
              title={'นักศึกษาที่สมัครงานโดยแบ่งตามชั้นปี'}
              year={activeStudyYear}
              setYear={year => {
                !get(appliedJobStudyYear, year) &&
                getAppliedJobStudyYear(dashboardDispatch, year)
                setActiveStudyYear(dashboardDispatch, year)
              }}
            />
          ) : (
            <Loader/>
          )}
          <Block
            marginTop="30px"
            padding="16px"
            backgroundColor="white"
            style={{ borderTop: '4px solid #fd7c2e' }}
          >
            <Label2 style={{ fontWeight: 'bold', marginBottom: 16 }}>
              จำนวนนักศึกษาที่สมัครงานแบ่งตามคณะ
            </Label2>
            {appliedJobStaticFaculty && appliedJobStaticFaculty.length > 0 ? (
              <ScrollTable
                headers={[ 'คณะ', 'จำนวน' ]}
                data={appliedJobStaticFaculty}
                keys={[ 'faculty_th', 'count' ]}
              />
            ) : (
              <Loader size="50px" height={50}/>
            )}
          </Block>
        </Block>
      </Block>
    </Block>
  )
}

export default withAdminNavigate(withDashboard(AppliedJob))
