// @flow
import * as React from "react"
import { Block } from "baseui/block"
import {
  EventSchedule20,
  Identification20,
  UserAvatar20,
  Building20,
} from "@carbon/icons-react"

const Icon = ({ name }) => {
  if (name === "Posted Job Report") {
    return <EventSchedule20 />
  }
  if (name === "Applied Job Report") {
    return <Identification20 />
  }
  if (name === "User") {
    return <UserAvatar20 />
  }
  if (name === "Work Place") {
    return <Building20 />
  }
  return ""
}

const NavigationMenu = ({ menu }) => {
  return (
    <Block display="flex" alignItems="center">
      <Block paddingRight="8px" display="flex" alignItems="center">
        <Icon name={menu.title} />
      </Block>
      {menu.title}
    </Block>
  )
}

export default NavigationMenu
