import React from 'react'
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from 'baseui/table'
import { Block } from 'baseui/block'
import { Tag } from 'baseui/tag'

const ScrollTable = ({ headers, data, tag, keys }) => {
  return (
    <Block height="200px">
      <StyledTable style={{ overFlowY: 'unset' }}>
        <StyledHead>
          {headers.map(header => (
            <StyledHeadCell
              style={{ justifyContent: 'center', fontWeight: 'bold' }}
            >
              {header}
            </StyledHeadCell>
          ))}
        </StyledHead>
        <StyledBody>
          {data.map((item, index) => {
            return (
              <StyledRow key={index}>
                <StyledCell>
                  {tag ? (
                    <Tag variant={'outlined'} kind="primary" closeable={false}>
                      {item[keys[0]] !== '' ? item[keys[0]] : 'อื่นๆ'}
                    </Tag>
                  ) : (
                    item[keys[0]] !== '' ? item[keys[0]] : 'อื่นๆ'
                  )}
                </StyledCell>
                <StyledCell style={{ justifyContent: 'center' }}>
                  {item[keys[1]]}
                </StyledCell>
              </StyledRow>
            )
          })}
        </StyledBody>
      </StyledTable>
    </Block>
  )
}

export default ScrollTable
