import React from 'react'
import { Block } from 'baseui/block'
import { Label2, Paragraph4 } from 'baseui/typography'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import YearSelect from 'components/YearSelect'

const colors = [ '#ff7c1b', '#ff903d', '#ffbe8d' ]
const renderDataKey = key => {
  if (key === 'pending_job') return 'สมัคร'
  if (key === 'confirm_job') return 'ผ่านเกณฑ์'
  if (key === 'total_job') return 'งานที่ประกาศ'
  if (key === 'closed_job') return 'ปิดรับ'
  return 'ไม่ผ่านเกณฑ์'
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <Block backgroundColor="#485465" width="120px" padding="5px">
        <Paragraph4 color="white" marginTop="2px" marginBottom="2px">
          {label}
        </Paragraph4>
        {payload.map(item => (
          <Block
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Block display="flex" alignItems="center">
              <Block
                backgroundColor={item.fill}
                width="10px"
                height="10px"
                style={{ borderRadius: '50%' }}
              />
              <Paragraph4
                color="white"
                fontSize="10px"
                marginTop="2px"
                marginBottom="2px"
                marginLeft="5px"
              >
                {renderDataKey(item.dataKey)}
              </Paragraph4>
            </Block>
            <Paragraph4
              color="white"
              fontSize="10px"
              marginTop="2px"
              marginBottom="2px"
            >
              {item.value}
            </Paragraph4>
          </Block>
        ))}
      </Block>
    )
  }

  return null
}

const BarGraphSection = ({ title, data, keys, year, setYear }) => {
  const renderCustomizedLegend = props => {
    const { payload } = props
    return (
      <Block display="flex" justifyContent="flex-end">
        {payload.map((item, index) => {
          return (
            <Block
              display="flex"
              alignItems="center"
              marginRight={index !== payload.length - 1 ? '15px' : '0px'}
            >
              <Block
                backgroundColor={item.color}
                width="10px"
                height="10px"
                style={{ borderRadius: '50%' }}
              />
              <Paragraph4
                fontSize="10px"
                marginBottom="2px"
                marginLeft="5px"
                marginTop="2px"
              >
                {renderDataKey(item.dataKey)}
              </Paragraph4>
            </Block>
          )
        })}
      </Block>
    )
  }
  return (
    <Block
      padding="16px"
      paddingTop="30px"
      backgroundColor="white"
      style={{
        boxShadow: '2px 2px 8px 2px rgba(0, 0, 0, 0.1)',
        borderTop: '5px solid #ff7c1b',
      }}
    >
      <Block
        display="flex"
        justifyContent="space-between"
        marginBottom="20px"
        alignItems="center"
      >
        <Block>
          <Label2 style={{ fontWeight: 'bold' }}>{title}</Label2>
        </Block>
        <Block>
          <YearSelect year={year} setYear={setYear}/>
        </Block>
      </Block>
      <BarChart width={510} height={250} data={data}>
        <Legend
          verticalAlign="top"
          height={30}
          align="right"
          iconType="circle"
          wrapperStyle={{ fontFamily: 'Prompt', fontSize: '13px' }}
          content={renderCustomizedLegend}
        />
        <CartesianGrid vertical={false}/>
        <XAxis
          dataKey="month"
          tick={{
            fontFamily: 'Prompt',
            fontSize: 12,
          }}
        />
        <YAxis
          scale={'linear'}
          tick={{
            fontFamily: 'Prompt',
            fontSize: 12,
          }}
        />
        <Tooltip content={<CustomTooltip/>}/>
        {keys.map((key, index) => (
          <Bar dataKey={key} fill={colors[index]}/>
        ))}
      </BarChart>
    </Block>
  )
}

export default BarGraphSection
