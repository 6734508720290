import React from "react"
import { Block } from "baseui/block"
import { Spinner } from "baseui/spinner"

const Loader = ({ size = "60px", height = 100 }) => (
  <Block display="flex" justifyContent="center" backgroundColor="white">
    <Spinner
      size={size}
      style={{ marginTop: height, marginBottom: height }}
      overrides={{
        ActivePath: {
          style: ({ $theme }) => ({ fill: $theme.colors.primary }),
        },
      }}
    />
  </Block>
)

export default Loader
