import React from 'react'
import { Block } from 'baseui/block'
import { Label2, Paragraph4 } from 'baseui/typography'
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts'
import YearSelect from 'components/YearSelect'

const COLORS = [ '#ff7c1b', '#ff903d', '#ffa35f', '#ffbe8d', '#ffe2cd' ]
const RADIAN = Math.PI / 180

const PieGraphSection = ({ data, dataKey, nameKey, title, year, setYear }) => {
  const renderCustomizedLabel = ({
                                   cx,
                                   cy,
                                   midAngle,
                                   innerRadius,
                                   outerRadius,
                                   percent,
                                 }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.18
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontFamily: 'Prompt', fontSize: 15 }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <Block backgroundColor="#485465" width="120px" padding="5px">
          {payload.map(item => (
            <Block
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Block display="flex" alignItems="center">
                <Block
                  backgroundColor={item.payload.fill}
                  width="10px"
                  height="10px"
                  style={{ borderRadius: '50%' }}
                />
                <Paragraph4
                  color="white"
                  fontSize="10px"
                  marginTop="2px"
                  marginBottom="2px"
                  marginLeft="5px"
                >
                  {item.name}
                </Paragraph4>
              </Block>
              <Paragraph4
                color="white"
                fontSize="10px"
                marginTop="2px"
                marginBottom="2px"
              >
                {item.value}
              </Paragraph4>
            </Block>
          ))}
        </Block>
      )
    }

    return null
  }
  const renderCustomizedLegend = () => {
    return (
      <Block display="flex" style={{ flexWrap: 'wrap' }}>
        {data.map((item, index) => {
          return (
            <Block
              display="flex"
              alignItems="center"
              marginRight={index !== data.length - 1 ? '20px' : '0px'}
            >
              <Block
                backgroundColor={COLORS[index]}
                width="10px"
                height="10px"
                style={{ borderRadius: '50%' }}
              />
              <Paragraph4
                fontSize="10px"
                marginBottom="2px"
                marginLeft="5px"
                marginTop="2px"
              >
                {item[nameKey]}
              </Paragraph4>
            </Block>
          )
        })}
      </Block>
    )
  }

  return (
    <Block
      style={{
        boxShadow: '2px 2px 8px 2px rgba(0, 0, 0, 0.1)',
        borderTop: '5px solid #ff7c1b',
      }}
      backgroundColor="white"
      padding="16px"
      paddingTop="30px"
    >
      <Block display="flex" justifyContent="space-between" marginBottom="20px">
        <Block>
          <Label2 style={{ fontWeight: 'bold' }}>{title}</Label2>
        </Block>
        <Block>
          <YearSelect year={year} setYear={setYear}/>
        </Block>
      </Block>
      {renderCustomizedLegend()}
      <ResponsiveContainer width="100%" height={270}>
        <PieChart>
          <Pie
            data={data}
            cx={'48.5%'}
            cy={140}
            outerRadius={125}
            innerRadius={75}
            dataKey={dataKey}
            nameKey={nameKey}
            label={renderCustomizedLabel}
            labelLine={false}
            isAnimationActive={false}
          >
            <Legend verticalAlign="top"/>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip/>}/>
        </PieChart>
      </ResponsiveContainer>
    </Block>
  )
}

export default PieGraphSection
