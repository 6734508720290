import CloudApi from "../cloudApi/api"

export const getUsers = dispatch => {
  return CloudApi.getUsers().then(data => {
    dispatch({
      type: "FETCH_USERS",
      payload: data,
    })
  })
}

export const getUserSummary = dispatch => {
  return CloudApi.getUserSummary().then(data => {
    dispatch({
      type: "FETCH_USER_SUMMARY",
      payload: data,
    })
  })
}

export const updateUserPage = (dispatch, page) => {
  return dispatch({
    type: "UPDATE_USER_PAGE",
    payload: page,
  })
}

export const getAppliedJobSummary = dispatch => {
  return CloudApi.getSummaryAppliedJob().then(data =>
    dispatch({
      type: "FETCH_SUMMARY_APPLIED_JOB",
      payload: data,
    })
  )
}

export const getMostAppliedJob = dispatch => {
  return CloudApi.getMostAppliedJob().then(data =>
    dispatch({
      type: "FETCH_MOST_APPLIED_JOB",
      payload: data,
    })
  )
}

export const getAppliedJobStaticYear = (dispatch, year) => {
  return CloudApi.getAppliedJobStaticYear(year).then(data =>
    dispatch({
      type: "FETCH_APPLIED_JOB_STATIC_YEAR",
      payload: { data, year },
    })
  )
}

export const getPostJobCount = dispatch => {
  return CloudApi.getPostJobCount().then(data =>
    dispatch({
      type: "FETCH_POST_JOB_SUMMARY",
      payload: data,
    })
  )
}

export const getPostedJobStaticYear = (dispatch, year) => {
  return CloudApi.getPostedJobStaticYear(year).then(data =>
    dispatch({
      type: "FETCH_POSTED_JOB_STATIC_YEAR",
      payload: { data, year },
    })
  )
}

export const getPostedJobLastest = dispatch => {
  return CloudApi.getPostedJobLastest().then(data =>
    dispatch({
      type: "FETCH_POSTED_JOB_LASTEST",
      payload: data,
    })
  )
}

export const getPostedJobStaticFaculty = (dispatch, year) => {
  return CloudApi.getPostedJobStaticFaculty(year).then(data =>
    dispatch({
      type: "FETCH_POSTED_STATIC_FACULTY",
      payload: { data, year },
    })
  )
}

export const getPostedJobStaticTag = dispatch => {
  return CloudApi.getPostedJobStaticTag().then(data =>
    dispatch({
      type: "FETCH_POSTED_STATIC_TAG",
      payload: data,
    })
  )
}

export const getAppliedJobStudyYear = (dispatch, year) => {
  return CloudApi.getAppliedJobStudyYear(year).then(data =>
    dispatch({
      type: "FETCH_APPLIED_JOB_STUDY_YEAR",
      payload: { data, year },
    })
  )
}

export const getAppliedJobStaticFaculty = dispatch => {
  return CloudApi.getAppliedJobStaticFaculty().then(data =>
    dispatch({
      type: "FETCH_APPLIED_JOB_STATIC_FACULTY",
      payload: data,
    })
  )
}

export const searchUser = (dispatch, keyword) => {
  return CloudApi.searchUsers(keyword).then(data =>
    dispatch({
      type: "SEARCH_USERS",
      payload: { data, keyword },
    })
  )
}

export const updateKeyWord = (dispatch, keyword) => {
  return dispatch({
    type: "UPDATE_KEYWORD",
    payload: keyword,
  })
}

export const setActiveStaticYear = (dispatch, year) => {
  return dispatch({
    type: "SET_POSTED_JOB_STATIC_YEAR_OPTIONS",
    payload: year,
  })
}

export const setActiveStaticFacultyYear = (dispatch, year) => {
  return dispatch({
    type: "SET_POSTED_JOB_STATIC_FACULTY_YEAR",
    payload: year,
  })
}

export const getWorkPlaceSummary = dispatch => {
  return CloudApi.getWorkplacesSummary().then(data =>
    dispatch({
      type: "FETCH_WORKPLACES_SUMMARY",
      payload: data,
    })
  )
}

export const updateUserTab = (dispatch, tab) => {
  return dispatch({
    type: "UPDATE_USER_TAB",
    payload: tab,
  })
}

export const getProfessor = (dispatch, offset, limit, keyword) => {
  return CloudApi.getProfessor(offset, limit, keyword).then(data =>
    dispatch({
      type: "FETCH_USERS_PROFESSOR",
      payload: {
        data: data.user,
        reset: offset === 0,
        keyword,
        count: data.count,
      },
    })
  )
}

export const getStudent = (dispatch, offset, limit, keyword) => {
  return CloudApi.getStudent(offset, limit, keyword).then(data =>
    dispatch({
      type: "FETCH_USERS_STUDENT",
      payload: {
        data: data.user,
        reset: offset === 0,
        keyword,
        count: data.count,
      },
    })
  )
}

export const getAdmin = (dispatch, offset, limit, keyword) => {
  return CloudApi.getAdmin(offset, limit, keyword).then(data =>
    dispatch({
      type: "FETCH_USERS_ADMIN",
      payload: {
        data: data.user,
        reset: offset === 0,
        keyword,
        count: data.count,
      },
    })
  )
}

export const updateStudentPage = (dispatch, page) => {
  return dispatch({
    type: "UPDATE_STUDENT_PAGES",
    payload: page,
  })
}

export const updateProfessorPage = (dispatch, page) => {
  return dispatch({
    type: "UPDATE_PROFESSOR_PAGES",
    payload: page,
  })
}

export const updateAdminPage = (dispatch, page) => {
  return dispatch({
    type: "UPDATE_ADMIN_PAGES",
    payload: page,
  })
}

export const editRoleUser = data => {
  return CloudApi.editUserRole(data)
}

export const insertUser = data => {
  return CloudApi.addUser(data)
}

export const setActiveStudyYear = (dispatch, year) =>
  dispatch({
    type: "SET_ACTIVE_STUDY_YEAR",
    payload: year,
  })

export const setAppliedJobYear = (dispatch, year) =>
  dispatch({
    type: "SET_APPLIED_JOB_YEAR",
    payload: year,
  })
